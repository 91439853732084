export default {
    init() {
        // JavaScript to be fired on all pages

        // // Jquery-match-height
        // $(function() {
        //     $('.example').matchHeight();
        // });

        // // Slick slider example
        // $('.slick__slider').slick({
        //     dots: true,
        //     infinite: true,
        //     speed: 300,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     responsive: [
        //         {
        //             breakpoint: 1024,
        //             settings: {

        //             },
        //         },
        //     ],
        // });

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });




        $(window).scroll(function () {
            let scroll = $(window).scrollTop();
            if (scroll > 72) {
                $('.header').addClass('header__fix');
            } else {
                $('.header').removeClass('header__fix');
            }
        });


        $(document).ready(function () {
            if (!$('body').hasClass('front-page-data')) {
                let $menu = $('#menu-hoofdmenu li a');
                $menu.each(function () {
                    $(this).attr('href', '/')
                })
            }

            $('.menu-demo-aanvragen a').remove();
            $('.menu-demo-aanvragen').append('<a href="#demo" class="btn btn-secondary" data-src="#demo" data-fancybox data-modal="true" title="Demo aanvragen">Demo aanvragen</a>')
        });


    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
