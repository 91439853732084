export default {
    init() {
        // JavaScript to be fired on the home page

        $('.nav-primary a[href*="#"]:not([href="#"]), .scroll[href*="#"]:not([href="#"])').on('click',function (e) {
            e.preventDefault();
            let offset = 150;
            let target = this.hash;
            if ($(this).data('offset') != undefined) {
                offset = $(this).data('offset');
            }
            $('html, body').stop().animate({
                'scrollTop': $(target).offset().top - offset,
            }, 800, 'swing', function () {
            });
        });

    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
